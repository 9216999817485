@use "/src/sass/theme.scss";

.PlanItem {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 32px 24px;
  border-radius: 12px;
  background-color: theme.color(white, base);
  box-shadow: theme.$elevation-1;
  color: theme.color(grey, mid);
}

.PlanItem--isCurrent {
  outline: 2px solid theme.color(primary, base);
}

.PlanItem__Header {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.PlanItem__Header h2 {
  text-transform: capitalize;
  color: theme.color(primary, base);
}

.PlanItem__Expiration {
  @include theme.typography("body-small");
}

.PlanItem__Header__Tag {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 6px 8px;
  border-radius: 12px;
  @include theme.typography("body-small-emphasis");
}

.PlanItem__Header__Tag--featured {
  background-color: theme.color(background, tag-active);
  color: theme.color(blue, base);
}

.PlanItem__Header__Tag--status {
  background-color: theme.color(primary, base);
  color: theme.color(white, base);
}

.PlanItem__Pricing {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.PlanItem__Pricing__Price {
  display: flex;
  align-items: baseline;
  gap: 4px;
  color: theme.color(primary, base);
}

.PlanItem__Pricing__Price__Number {
  font-size: 32px;
  font-weight: 800;
}

.PlanItem__Pricing__Price__Rate {
  @include theme.typography("body-small-emphasis");
}

.PlanItem__Pricing__Description {
  @include theme.typography("body-small-emphasis");
}

.PlanItem__Docs {
  display: flex;
  gap: 8px;
  align-items: center;
  @include theme.typography("body-emphasis");
}

.PlanItem__Benefits {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.PlanItem__Benefits__Item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.PlanItem__CTA {
  align-self: flex-start;
  margin-top: auto;
}
