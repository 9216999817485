@use "/src/sass/theme.scss";

.DocumentFooter {
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  text-align: center;
  border-top: 1px solid theme.color(grey, base);
  color: theme.color(grey, mid);
  padding-top: 16px;
  margin-top: auto;
  gap: 4px;
}

.DocumentFooter__Pay {
  display: flex;
  flex-direction: column;
  color: theme.color(black);
  h4 {
    font-weight: 600;
  }
}

.DocumentFooter__Penalties,
.DocumentFooter__Pay__Payment {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.DocumentFooter__Pay__Payment {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.DocumentFooter__Pay__Payment__Details {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.DocumentFooter__Signature {
  border-top: 1px solid theme.color(grey);
  padding: 16px 0;
  color: theme.color(grey);
  @include theme.typography("body-label");
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.DocumentFooter__Signature__Logo {
  opacity: 0.5;
  width: 1.5cm;
}
