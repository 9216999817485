@use "/src/sass/theme.scss";

.Navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: theme.$header-height;
  padding: 0 24px;
  z-index: 100;
  display: flex;
  justify-content: center;
  transition: height border theme.get-animation(speed, controllers)
    theme.get-animation(ease, default);
}

.Navigation--with-right-panel {
  padding-right: theme.$right_panel_width + 24px;
}

.Navigation__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1148px;
  flex: 1;
  border-bottom: 1px solid theme.color(background, base);
}

.Navigation__content__logo {
  width: 90px;
}

.Navigation__content__links {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.Navigation--with-right-panel .Navigation__content__links {
  transform: translateX(calc(-50% - theme.$right_panel_width / 2));
}
