@use "/src/sass/theme.scss";

.TabItem {
  padding: 8px 12px;
  border-radius: 12px;
  @include theme.typography("nav");
  color: theme.color(grey, mid);
  transition: all theme.get-animation(speed, controllers)
    theme.get-animation(ease, default);
}

.TabItem:not(:last-child) {
  margin-right: 8px;
}

.TabItem--active,
.TabItem:hover {
  color: theme.color(primary);
  background-color: theme.color(background, nav-active);
}
