.PlansPage {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}

.PlansPage__Header {
  margin-bottom: 16px;
}
