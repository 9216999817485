@use "/src/sass/theme.scss";

.Dropzone {
  margin-top: 16px;
}

.Dropzone__Zone {
  height: 64px;
  width: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed theme.color(grey);
  border-radius: 8px;
  cursor: pointer;
  @include theme.typography(body-small-emphasis);
  color: theme.color(grey, mid);
  transition: theme.get-animation(speed, controllers)
    theme.get-animation(ease, default);
}

.Dropzone__Zone--active,
.Dropzone__Zone:hover {
  background-color: theme.color(background, base);
  border: 2px dashed theme.color(primary);
  color: theme.color(primary);
}

.Dropzone__Zone--loading {
  background: linear-gradient(
    90deg,
    theme.color(background, base) 0%,
    theme.color(white) 100%
  );
  background-size: 200% 200%;
  animation: gradient 1s ease infinite;
  border: 2px dashed theme.color(grey);
  color: theme.color(grey, mid);
}

.Dropzone__Preview {
  border: 1px solid theme.color(grey);
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.LogoPreviewer__Img {
  max-height: 64px;
  max-width: 100%;
  object-fit: contain;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
