@use "/src/sass/theme.scss";

.Footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4rem;
  padding-bottom: 2rem;
  gap: 0.5rem;
  color: theme.color(grey, mid);
  @include theme.typography("body-small");
}

.Footer__links {
  @include theme.typography("body-small");
  display: flex;
  gap: 1rem;
}

.Footer__build {
  opacity: 0.5;
}

.Footer__links--bold {
  @include theme.typography("body-small-emphasis");
}
