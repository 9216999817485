// Stabbl
$primary: #1c2d37;
$red: #db232e;
$blue: rgb(24, 66, 216);
$blue-light: rgba(24, 66, 216, 0.1);
$green: rgb(0, 210, 84);
$green-light: rgb(0, 210, 84, 0.1);
$green-dark: rgb(48, 161, 93);
$purple: rgb(151, 71, 255);
$purple-light: rgb(151, 71, 255, 0.1);
$primary-gradient: linear-gradient(285.77deg, $primary 32.93%, #294251 77.26%);

// Utils
$white: #ffffff;
$white-light: rgb(255, 255, 255, 0.1);
$black: #1e1e1e;
$grey: #e1e1e1;
$grey-mid: #545454;

// BG
$background-grey: #f3f3f3;
$background-sand: #f7f3ee;
$background-tooltip: rgba(28, 45, 55, 0.8);
$background-tag-active: rgba(24, 66, 216, 0.15);
$background-nav-active: rgba(28, 45, 55, 0.1);

$colors: (
  "primary": (
    base: $primary,
    gradient: $primary-gradient,
  ),
  "red": (
    base: $red,
  ),
  "blue": (
    base: $blue,
    light: $blue-light,
  ),
  "green": (
    base: $green,
    light: $green-light,
    dark: $green-dark,
  ),
  "purple": (
    base: $purple,
    light: $purple-light,
  ),
  "white": (
    base: $white,
    light: $white-light,
  ),
  "black": (
    base: $black,
  ),
  "grey": (
    base: $grey,
    mid: $grey-mid,
  ),
  "background": (
    base: $background-grey,
    sand: $background-sand,
    translucid: mix($background-sand, rgba(255, 255, 255, 0), 90),
    tooltip: $background-tooltip,
    tag-active: $background-tag-active,
    nav-active: $background-nav-active,
  ),
);
