@use "/src/sass/theme.scss";

.CreateDocumentPageView {
  display: flex;
  top: theme.$header_height; // Sticky header height + layout padding
  position: sticky; // Ensure it stays in the viewport
  height: calc(
    100vh - theme.$header_height - 48px
  ); // Screen width - header - layout padding
}

.CreateDocumentPageView__Preview {
  position: absolute;
  width: 100%;
  // Absolute + 100% width allow to compute the actual available width regardless of parents constraints
  display: flex;
  justify-content: center;
}

.CreateDocumentPageView__Preview__StickyWrapper__Doc {
  box-shadow: theme.$elevation-paper;
}
