@use "/src/sass/theme.scss";

.Button {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: fit-content;
  @include theme.typography("body-emphasis");
  padding: 10px 12px;
  color: theme.color(white);
  background: theme.color(primary, gradient);
  border-radius: 8px;
  border: 1px solid theme.color(white, light);
  transition: opacity theme.get-animation(speed, controllers)
    theme.get-animation(ease, default);
  gap: 8px;
}

.Button:hover {
  opacity: 0.9;
}

.Button--rounded {
  border-radius: 8px;
}

.Button--stretched {
  width: 100%;
  height: 48px;
  justify-content: center;
}

// colors
.Button--danger {
  background: theme.color(red);
  border: 2px solid theme.color(red);
  transition: all theme.get-animation(speed, controllers)
    theme.get-animation(ease, default);
}

.Button--grey {
  color: theme.color(grey, mid);
  background: theme.color(background, nav-active);
  border: 2px solid transparent;
  transition: all theme.get-animation(speed, controllers)
    theme.get-animation(ease, default);
}

// variants

.Button--outlined {
  background: transparent;
  color: theme.color(primary);
  border: 2px solid theme.color(primary);
  transition: all theme.get-animation(speed, controllers)
    theme.get-animation(ease, default);
}

.Button--outlined.Button:hover {
  background: theme.color(primary);
  color: theme.color(white);
  opacity: 1;
}

.Button--subtle {
  background: transparent;
  color: theme.color(primary);
  border: 2px solid transparent;
  transition: all theme.get-animation(speed, controllers)
    theme.get-animation(ease, default);
}

.Button--subtle.Button:hover {
  border-color: theme.color(background, base);
  background-color: theme.color(background, base);
  opacity: 1;
}

.Button--link {
  background: transparent;
  padding: 0;
  color: theme.color(primary);
  @include theme.typography("body-small-emphasis");
  transition: all theme.get-animation(speed, controllers)
    theme.get-animation(ease, default);
}

.Button--link.Button:hover {
  opacity: 0.75;
}

// disabled

.Button--disabled,
.Button--disabled:hover {
  opacity: 0.7;
  cursor: not-allowed;
}
