@use "/src/sass/theme.scss";

.FormProgressBar {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0px 4px;
  @include theme.typography("body-label");
  color: theme.color(grey, mid);
  font-weight: 600;
  gap: 4px;
  width: fit-content;
}

.FormProgressBar__progress {
  display: flex;
  align-items: center;
  gap: 8px;
}

.FormProgressBar__counter__values {
  color: theme.color(grey, mid);
  display: flex;
}

.FormProgressBar__progress_circle {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: visible;
  transform-origin: center;
}

.FormProgressBar__progress_circle__background,
.FormProgressBar__progress_circle__foreground {
  fill: transparent;
  transition: all 250ms ease-in-out;
}

.FormProgressBar__progress_circle__background {
  stroke: theme.color(grey, base);
}

.FormProgressBar__progress_circle__foreground {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: theme.color(primary, base);
  transform: rotate(-90deg);
  transform-origin: center;
}

.FormProgressBar__progress_circle__tick {
  opacity: 0;
}

/* ------------------------------- Valid state ------------------------------ */

.FormProgressBar--valid {
  border-radius: 4px;
  color: theme.color(green, dark);
  background-color: theme.color(green, light);
  border: 1px solid theme.color(green, base);
  padding: 3px 5px; // Tag style
}

$validation-animation-timing: 150ms;

.FormProgressBar--valid .FormProgressBar__progress_circle__foreground,
.FormProgressBar--valid .FormProgressBar__progress_circle__background {
  animation: 250ms ease-in forwards hide-animation;
}

.FormProgressBar--valid .FormProgressBar__progress_circle__foreground {
  stroke: theme.color(green, dark);
  fill: theme.color(green, light);
  transition: $validation-animation-timing ease-in;
}

.FormProgressBar--valid .FormProgressBar__progress_circle__tick {
  stroke: theme.color(green, dark);
  fill: transparent;
  animation: cubic-bezier(0.65, 0, 1, 1) forwards tick-animation;
  // animation-timing-function: theme.;
  animation-duration: 250ms;
  animation-delay: $validation-animation-timing;
  stroke-dasharray: 0, 14px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform-origin: center;
}

.FormProgressBar--valid .FormProgressBar__tag {
  animation: 250ms ease-out forwards show-animation;
  // animation-timing-function: theme.;
  animation-delay: $validation-animation-timing;
}

/* ------------------------------- Animations ------------------------------- */

@keyframes tick-animation {
  from {
    opacity: 0;
    stroke-dasharray: 0, 30px;
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    stroke-dasharray: 30px, 30px;
    transform: scale(1);
  }
}

@keyframes hide-animation {
  from {
    opacity: 1;
    // transform: rotate(0);
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
    // transform: rotate(0.5);
  }
}
@keyframes show-animation {
  from {
    opacity: 0;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
