@use "/src/sass/theme.scss";

.LoginPage {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: theme.color(background, sand);
  height: 100vh;
  width: 100%;
  padding: 40px;
}

.LoginPage__form {
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  margin-bottom: 48px;
  padding: 0 80px;
}

.LoginPage__form__logo {
  height: 64px;
}

.LoginPage__form__labels {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include theme.typography("body");
}

.LoginPage__cover {
  flex: 4;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.LoginPage__cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}
