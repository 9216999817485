@use "/src/sass/theme.scss";

.CreateDocumentForm {
  display: flex;
  flex-direction: column;
  background-color: theme.color(white);
  width: theme.$right_panel_width;
  padding-top: 28px; // Why magic number ? alignement with header
  padding-bottom: 80px;
  padding-left: 32px;
  padding-right: 32px;
  box-shadow: theme.$elevation-1;
  min-height: 100vh;
}

.CreateDocumentForm__Actions {
  margin-top: 2rem;
}
