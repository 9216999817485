@use "/src/sass/theme.scss";

.DangerZone {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  border-radius: 12px;
  box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.02);
  background-color: theme.color(white);
  border: 2px solid theme.color(red);
  gap: 24px;
}

.DangerZone__Logout {
  border-bottom: 1px solid theme.color(grey);
  width: 100%;
  padding-bottom: 24px;
}

.DangerZone__DeleteAccount {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
