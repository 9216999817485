@use "/src/sass/theme.scss";

.Textarea__container {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.Textarea__container textarea {
  @include theme.typography(body-small-emphasis);
  width: 100%;
  padding-top: 8px;
  box-sizing: border-box;
  min-height: 120px;
  outline: none;
  outline-width: 0;
  padding-top: 18px;
  padding-left: 11px; // + 1px from border
  padding-right: 36px;
  border-radius: 4px;
  color: theme.color(black);
  -webkit-appearance: none;
}

.Textarea__container .Textarea__label--default,
.Textarea__container .Textarea__label--focused {
  font-size: 11px;
  text-overflow: ellipsis;
  user-select: none;
  pointer-events: none;
  left: 8px;
  padding: 0 4px;
  position: absolute;
  transition:
    top theme.get-animation(speed, controllers)
      theme.get-animation(ease, default),
    color theme.get-animation(speed, controllers)
      theme.get-animation(ease, default),
    font-size theme.get-animation(speed, controllers)
      theme.get-animation(ease, default);
}

.Textarea__container .Textarea__label--default {
  font-size: 14px;
  line-height: 6px;
  font-weight: 400;
  color: theme.color(grey, mid);
  top: 20px;
  transform: translateY(-50%);
}

.Textarea__container .Textarea__label--focused {
  top: 2px; // border-size
  padding-top: 6px;
}

.Textarea__container .Textarea__label--disabled {
  font-weight: 400;
  color: theme.color(grey, mid);
}

.Textarea__container .Textarea__icon {
  line-height: 40px;
  user-select: none;
  pointer-events: none;
  position: absolute;
  right: 12px;
}

// default

.Textarea__container--default textarea {
  border: solid 1px theme.color(grey);
  background-color: theme.color(white);
}

.Textarea__container--default textarea:focus {
  outline: none;
  outline-width: 0;
  padding-top: 17px;
  border: solid 2px theme.color(primary);
  padding-left: 10px; // + 2px from border
}

.Textarea__container--default .Textarea__label--focused {
  color: theme.color(primary);
  background-color: theme.color(white);
  width: calc(100% - 20px); //full width minus left positionning + padding
}

.Textarea__container--default-filled textarea {
  border: solid 1px theme.color(grey);
}

// error

.Textarea__container--error textarea {
  border: solid 2px theme.color(red);
  background-color: theme.color(white);
}

.Textarea__container--error .Textarea__label--focused {
  color: theme.color(red);
}

// disabled

.Textarea__container--disabled textarea {
  background-color: theme.color(background);
  cursor: not-allowed;
  user-select: none;
  border: solid 1px theme.color(grey, mid);
}

.Textarea__container--disabled label,
.Textarea__container--disabled textarea {
  color: theme.color(grey, mid);
}
