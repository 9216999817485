@use "/src/sass/theme.scss";

.ItemsTable {
  display: flex;
  flex-direction: column;
  font-size: 13px;
}

.ItemsTable__Header {
  display: flex;
  @include theme.typography("body-small-emphasis");
  padding: 8px 0;
  font-size: 11px;
  border-bottom: 1.5px solid theme.color(black);
}

.ItemsTable__Body {
  // border-bottom: 1px solid theme.color(grey);
}

.ItemsTable__Body__Item {
  display: flex;
  width: 100%;
  padding: 6px 0;
  align-items: center;
  font-size: 13px;
}

.ItemsTable__Header__Name,
.ItemsTable__Body__Item__Designation {
  flex: 3;
}

.ItemsTable__Body__Item__Designation {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.ItemsTable__Header__Qty,
.ItemsTable__Header__Unit,
.ItemsTable__Header__Price,
.ItemsTable__Header__Total,
.ItemsTable__Body__Item__Qty,
.ItemsTable__Body__Item__Unit,
.ItemsTable__Body__Item__Price,
.ItemsTable__Body__Item__Total {
  flex: 1;
  text-align: right;
  font-weight: 500;
  line-height: 18px;
}

.ItemsTable__Body__Item__Designation__Name,
.ItemsTable__Body__Item__Total {
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}

.ItemsTable__Body__Item__Designation__Description {
  color: theme.color(grey, mid);
}

.ItemsTable__Footer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: flex-end;
  margin-top: 48px;
  width: 55%;
}

.ItemsTable__Footer_NoVAT {
  text-align: right;
  font-style: italic;
  color: theme.color(grey, mid);
  padding: 16px 0;
}

.ItemsTable__Footer__Total {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  line-height: 18px;
  padding: 8px 0;
  border-top: 1px solid theme.color(grey, base);
}

.ItemsTable__Footer__Total-TTC {
  font-weight: 700;
}

.ItemsTable__Footer__Total__Label {
  flex: 1;
}
.ItemsTable__Footer__Total__Value {
  text-align: right;
}
