@use "/src/sass/theme.scss";

.LabelledCheckbox {
  display: flex;
  align-items: center;
  user-select: none;
}

.LabelledCheckbox__label {
  @include theme.typography(body-small);
  cursor: pointer;
  color: theme.color(grey, mid);
  padding-left: 12px;
}

.LabelledCheckbox__label--disabled {
  cursor: not-allowed;
}
