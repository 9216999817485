$elevation-1:
  0px 0px 1px rgba(0, 0, 0, 0.039),
  0px 0.5px 1.5px rgba(0, 0, 0, 0.19);

$elevation-2:
  0px 0.333333px 1.5px rgba(0, 0, 0, 0.039),
  0px 1.25px 5px rgba(0, 0, 0, 0.19);

$elevation-3:
  0px 0.25px 3px rgba(0, 0, 0, 0.039),
  0px 2.75px 9px rgba(0, 0, 0, 0.19);

$elevation-4:
  0px 0.5px 5px rgba(0, 0, 0, 0.039),
  0px 3.75px 11px rgba(0, 0, 0, 0.19);

$elevation-5:
  0px 1px 6px rgba(0, 0, 0, 0.039),
  0px 5.5px 16px rgba(0, 0, 0, 0.19);

$elevation-paper:
  0px 0px 0px 1.2px rgba(209, 209, 209, 0.25),
  0px 18px 42px rgba(0, 0, 0, 0.1),
  0px 6px 18px rgba(0, 0, 0, 0.005);
