@use "/src/sass/theme.scss";

.ClientBlock {
  display: flex;
  gap: 8px;
  flex-direction: column;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.02);
  background-color: theme.color(white);
  align-items: center;
  text-align: center;
}

.ClientBlock:last-child {
  flex-grow: 0;
}

.ClientBlock__logo {
  margin-bottom: 8px;
}

.ClientBlock__name {
  @include theme.typography("body-fat");
}

.ClientBlock__meta {
  @include theme.typography("body-small");
  color: theme.color(grey, mid);
}

.ClientBlock__CompanyLogo {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  overflow: hidden;
  background-color: theme.color(grey, base);
}

.ClientBlock__CompanyLogo__letter {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: theme.color(primary);
  @include theme.typography("body-fat");
}

.ClientBlock__CompanyLogo__img {
  height: 100%;
  width: 100%;
}
