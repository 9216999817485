@use "/src/sass/theme.scss";

.TopLoadingBar {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 2px;
  border-radius: 12px;
  transition: height theme.get-animation(speed, controllers)
    theme.get-animation(ease, default);
}

.TopLoadingBar__bar {
  position: relative;
  width: 100%;
  border-radius: 12px;
  background-color: theme.color(background, nav-active);
  transition: height theme.get-animation(speed, controllers)
    theme.get-animation(ease, default);
}

.TopLoadingBar__value {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  background-color: theme.color(primary, base);
  border-radius: 12px;
  width: 50%;
}

.TopLoadingBar__value--indeterminate {
  animation: indeterminateAnimation 1s infinite forwards ease-in-out;
}

@keyframes indeterminateAnimation {
  0% {
    left: -50%;
  }
  100% {
    left: 100%;
  }
}
