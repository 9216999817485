@use "/src/sass/theme.scss";

.LogoField__input {
  display: flex;
  gap: 24px;
}

.LogoField__input__field {
  margin-top: 12px;
  flex: 1;
}
