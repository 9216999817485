@use "/src/sass/theme.scss";

.Table {
}

.Table__Head__Row {
  text-align: left;
}

.Table__Body__Row {
  border-top: 1px solid theme.color(grey, base);
  text-align: left;
}

.Table__Head__Row__Cell {
  padding: 16px 0;
  color: theme.color(grey, mid);
  display: flex;
  align-items: center;
  gap: 8px;
  user-select: none;
  cursor: pointer;
}

.Table__Head__Row__Cell__Label {
  @include theme.typography("body-small-emphasis");
}

.Table__Head__Row__Cell__Icon {
  display: flex;
  width: 24px;
}

.Table__Body__Row__Cell {
  padding: 12px 0;
  vertical-align: middle;
  @include theme.typography("body-small");
  color: theme.color(black, base);
}
