@use "/src/sass/theme.scss";

.DocumentsHistoryPageView {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.DocumentsHistoryPageView__List {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 8px 32px;
  border-radius: 12px;
  box-shadow: theme.$elevation-1;
  background-color: theme.color(white);
}
