@use "/src/sass/theme.scss";

.CompanyField__suggestion {
  @include theme.typography("body-small");
  display: flex;
  flex-direction: column;
}

.CompanySuggestion__name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  @include theme.typography("body-small-emphasis");
}

.CompanySuggestion__infos__address {
  white-space: pre-line;
  margin-bottom: 4px;
}
