@mixin text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin typography($preset: body) {
  @each $key, $value in map-get-strict($typography-map, $preset) {
    #{$key}: $value;
  }
}

@mixin z-index($preset: base, $offset: 0) {
  z-index: map-get-strict($z-index-map, $preset) + $offset;
}

$scrollbars-thumb-size: 4px;
$scrollbars-thumb-spacing: 4px;

@mixin custom-scrollbars {
  &::-webkit-scrollbar {
    width: $scrollbars-thumb-size + $scrollbars-thumb-spacing * 2;
    height: $scrollbars-thumb-size + $scrollbars-thumb-spacing * 2;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $scrollbars-thumb-size / 2 + $scrollbars-thumb-spacing;
    border: $scrollbars-thumb-spacing solid transparent;
    background-clip: padding-box;
    background-color: #cdcdcd;
  }
}
