@use "/src/sass/theme.scss";

.UserBlock {
  display: flex;
  align-items: center;
}

.UserBlock__credits {
  display: flex;
  align-items: center;
  @include theme.typography("body-small");
  gap: 8px;
  padding-right: 12px;
  border-right: 1px solid theme.color(grey, base);
  color: theme.color(grey, mid);
}

.UserBlock__user {
  display: flex;
  align-items: center;
  margin-left: 8px;
  padding: 6px 10px;
  border-radius: 12px;
  gap: 8px;
  background-color: transparent;
}

.UserBlock__user__name {
  @include theme.typography("nav");
  color: theme.color(grey, mid);
}

.UserBlock__user--active {
  transition: all 250ms ease-in-out;
  background-color: theme.color(background, nav-active);
}

.UserBlock__user--active .UserBlock__user__name {
  color: theme.color(primary);
}

.UserBlock__user__picture {
  height: 24px;
  border-radius: 12px;
}
