@use "/src/sass/theme.scss";

.DocumentPreview {
  background: theme.color(white);
  @include theme.typography("body-small");
  color: #000;
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 14mm;
  padding-bottom: 7mm;
  padding-right: 12mm;
  padding-left: 12mm;
  width: 210mm;
  min-height: 297mm;
  font-size: 13px;
}

@media print {
  .Layout > header,
  .Layout .Footer {
    display: none;
  }
  .DocumentPreview {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    padding-bottom: 0;
    min-height: 285mm;
    width: 210mm;
  }
  #sentry-feedback {
    display: none;
  }
}
