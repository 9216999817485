.Illustration {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.Illustration__image {
  user-select: none;
  pointer-events: none;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
