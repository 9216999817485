// From https://developers.google.com/identity/branding-guidelines?hl=fr

@use "/src/sass/theme.scss";

.Button__Google__Auth {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid theme.color(grey, base);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: theme.color(primary);
  @include theme.typography("body-emphasis");
  cursor: pointer;
  overflow: hidden;
  padding: 10px 12px;
  position: relative;
  text-align: center;
  -webkit-transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.Button__Google__Auth .Button__Google__Auth__icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.Button__Google__Auth .Button__Google__Auth__contentWrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.Button__Google__Auth .Button__Google__Auth__contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.Button__Google__Auth .Button__Google__Auth__state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.Button__Google__Auth:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.Button__Google__Auth:disabled .Button__Google__Auth__contents {
  opacity: 38%;
}

.Button__Google__Auth:disabled .Button__Google__Auth__icon {
  opacity: 38%;
}

.Button__Google__Auth:not(:disabled):active .Button__Google__Auth__state,
.Button__Google__Auth:not(:disabled):focus .Button__Google__Auth__state {
  background-color: #303030;
  opacity: 12%;
}

.Button__Google__Auth:not(:disabled):hover {
  -webkit-box-shadow:
    0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow:
    0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.Button__Google__Auth:not(:disabled):hover .Button__Google__Auth__state {
  opacity: 8%;
}
