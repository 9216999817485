@use "/src/sass/theme.scss";

.BillingModeSwitchAction:hover {
  opacity: 1;
}

.BillingModeSwitchAction--discounted::after {
  position: absolute;
  top: -16px;
  right: -40px;
  @include theme.typography("body-label");
  font-weight: 700;
  content: "-25%";
  color: theme.color(white);
  background-color: theme.color("purple");
  border-radius: 8px;
  padding: 6px 8px;
}
