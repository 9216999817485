@use "/src/sass/theme.scss";

// Yep, that's right!
$Spinner-strokeLength: 175.6449737548828;

.Spinner {
  animation: Spinner-containerAnimation 2s linear infinite;
}

@keyframes Spinner-containerAnimation {
  100% {
    transform: rotate(360deg);
  }
}

.Spinner__circle {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  transform: rotate(-90deg);
  stroke: theme.color(primary);
  stroke-dasharray: 1, 300;
  stroke-dashoffset: 0;
  animation: Spinner-strokeAnimation 3s ease infinite;
  transform-origin: center center;
}

@keyframes Spinner-strokeAnimation {
  0% {
    stroke-dasharray: 1, 300;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 150, 300;
    stroke-dashoffset: -$Spinner-strokeLength / 4;
  }

  100% {
    stroke-dasharray: 150, 300;
    stroke-dashoffset: -$Spinner-strokeLength;
  }
}
