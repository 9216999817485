@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@500&display=swap");

$typography-body: "Inter", sans-serif;
$typography-header: "Playfair Display", serif;

$typography-map: (
  h1: (
    font-family: $typography-header,
    font-size: 32px,
    font-weight: 500,
  ),
  h2: (
    font-family: $typography-header,
    font-size: 24px,
    font-weight: 500,
  ),
  h3: (
    font-family: $typography-header,
    font-size: 20px,
    font-weight: 500,
  ),
  h4: (
    font-family: $typography-body,
    font-size: 16px,
    font-weight: 600,
  ),
  title-block: (
    font-family: $typography-header,
    font-size: 22px,
    font-weight: 500,
  ),
  body: (
    font-family: $typography-body,
    font-size: 16px,
    font-weight: 400,
  ),
  body-display: (
    font-family: $typography-header,
    font-size: 22px,
    font-weight: 500,
  ),
  body-small: (
    font-family: $typography-body,
    font-size: 14px,
    font-weight: 300,
  ),
  body-emphasis: (
    font-family: $typography-body,
    font-size: 16px,
    font-weight: 600,
  ),
  body-small-emphasis: (
    font-family: $typography-body,
    font-size: 14px,
    font-weight: 500,
    line-height: 21px,
  ),
  body-small-fat: (
    font-family: $typography-body,
    font-size: 14px,
    font-weight: 700,
    line-height: 21px,
  ),
  body-fat: (
    font-family: $typography-body,
    font-size: 16px,
    font-weight: 700,
  ),
  body-label: (
    font-family: $typography-body,
    font-size: 12px,
    font-weight: 500,
  ),
  nav: (
    font-family: $typography-body,
    font-size: 14px,
    font-weight: 600,
  ),
);
