@use "/src/sass/theme.scss";

.ItemElement {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ItemElement__unit {
  flex: 5;
}

.ItemElement__unitPrice,
.ItemElement__quantity {
  flex: 2;
}
