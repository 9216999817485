@use "/src/sass/theme.scss";

.DropdownList {
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  max-height: 50vh;
  box-shadow:
    0px 3.75px 11px 0px rgba(0, 0, 0, 0.19),
    0px 0.5px 5px 0px rgba(0, 0, 0, 0.04);
  background-color: theme.color(white);
  overflow-y: hidden;
  z-index: 100;
}

.DropdownList__item {
  @include theme.typography("body-small");
  padding: 8px 16px;
  cursor: pointer;
}

.DropdownList__item--highlighted,
.DropdownList__item:hover {
  background-color: theme.color(background);
}
