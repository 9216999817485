@use "/src/sass/theme.scss";

.Icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

@each $color in map-keys(theme.$colors) {
  $shades: map-keys(map-get(theme.$colors, $color));
  @each $shade in $shades {
    .Icon--#{$color}-#{$shade} * {
      fill: theme.color($color, $shade);
    }
  }
}
