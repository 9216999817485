@use "/src/sass/theme.scss";

.ItemRecap {
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 8px 0;
}

.ItemRecap__actions {
  position: absolute;
  top: 0px;
  right: 0px;
  transition: background 150ms ease-in-out;
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
}

.ItemRecap:hover {
  cursor: pointer;
}

.ItemRecap__actions__edit,
.ItemRecap__actions__delete {
  display: flex;
  padding: 8px;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
}

.ItemRecap:hover .ItemRecap__actions__edit {
  background-color: theme.color(background, base);
}

.ItemRecap__actions__delete {
  opacity: 0;
  transition: opacity theme.get-animation(speed, controllers)
    theme.get-animation(ease, default);
}

.ItemRecap:hover .ItemRecap__actions__delete {
  opacity: 1;
}

.ItemRecap__infos {
  display: flex;
  flex-direction: column;
  @include theme.typography("body");
  color: theme.color(grey, mid);
}

.ItemRecap__infos__name {
  color: theme.color(black);
  margin-bottom: 8px;
}
