@use "/src/sass/theme.scss";

.Tag {
  display: flex;
  align-items: center;
  padding: 3px 6px;
  border-radius: 4px;
  @include theme.typography("body-label");
  font-weight: 600;
  gap: 4px;
  width: fit-content;
}

.Tag--green {
  color: theme.color(green, dark);
  background-color: theme.color(green, light);
  border: 1px solid theme.color(green, base);
}

.Tag--purple {
  color: theme.color(purple, base);
  background-color: theme.color(purple, light);
  border: 1px solid theme.color(purple, base);
}

.Tag--blue {
  color: theme.color(blue, base);
  background-color: theme.color(blue, light);
  border: 1px solid theme.color(blue, base);
}
