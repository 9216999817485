@use "/src/sass/theme.scss";

.Layout {
  display: flex;
  justify-content: center;
  background-color: theme.color(background, sand);
  min-height: 100vh;
  width: 100%;
  padding: 0 24px;
}

.Layout--with-right-pannel {
  padding-right: 0;
}

.Layout__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1148px;
  flex: 1;
  padding-top: theme.$header-height;
  margin: 0 auto;
}

.Layout__section {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.Layout__right-panel {
  padding-left: 24px;
  z-index: 200;
}
