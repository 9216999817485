@use "/src/sass/theme.scss";

.DocumentHeader {
  display: flex;
}

.DocumentHeader__Left {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.DocumentHeader__Right {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 18px;
  padding-left: 20%;
}

.DocumentHeader__UserCompany {
  display: flex;
  flex: 1;
  line-height: 18px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.DocumentHeader__UserCompany__Logo {
  max-height: 90px;
  align-self: flex-start;
  margin-bottom: 1rem;
}

.DocumentHeader__UserCompany__Logo img {
  max-height: 64px;
  max-width: 100%;
  object-fit: contain;
}

.DocumentHeader__UserCompany__Row {
  display: flex;
  flex-direction: column;
  white-space: pre-line;
}

.DocumentHeader__UserCompany__Row--fat {
  font-weight: 600;
}

.DocumentHeader__Meta {
  display: flex;
  flex-direction: column;
}

.DocumentHeader__Client {
  display: flex;
  flex-direction: column;
  line-height: 18px;
  gap: 4px;
  border: 1px solid theme.color(grey, base);
  padding: 16px 18px;
}

.DocumentHeader__Client__Row {
  display: flex;
  flex-direction: column;
  white-space: pre-line;
}

.DocumentHeader__Client__Row--fat {
  font-weight: 600;
}

.DocumentHeader__DocumentType {
  font-size: 18px;
  font-weight: 600;
  color: theme.color(grey, mid);
  text-align: right;
}

.DocumentHeader__Meta__Info {
  display: flex;
  flex-direction: row;
  text-align: right;
  align-items: center;
}

.DocumentHeader__Meta__Info__Value {
  flex: 1;
}

.DocumentHeader__Meta__Info__Label {
  flex: 1;
  line-height: 18px;
  font-weight: 600;
}

.DocumentHeader__Name {
  font-size: 18px;
  font-weight: 600;
  padding: 16px 0;
  margin-top: 32px;
}
