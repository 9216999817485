@use "/src/sass/theme.scss";

@function createCheckboxBorder($width, $color) {
  @return inset 0 0 0 $width $color;
}

.Checkbox {
  position: relative;
  cursor: pointer;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  box-shadow: createCheckboxBorder(1px, theme.color(grey));
  background-color: theme.color(white);
  transition:
    background-color theme.get-animation(speed, controllers)
      theme.get-animation(ease, default),
    box-shadow theme.get-animation(speed, controllers)
      theme.get-animation(ease, default);
}

.Checkbox--checked {
  box-shadow: createCheckboxBorder(1px, theme.color(primary));
  background-color: theme.color(primary);
  color: theme.color(white);
}

.Checkbox--disabled {
  box-shadow: createCheckboxBorder(1px, theme.color(grey));
  cursor: not-allowed;
}

.Checkbox--disabled.Checkbox--checked {
  background-color: theme.color(grey);
}

.Checkbox__nativeInput {
  @extend %reset-input-styles;
  position: absolute;
  opacity: 0;
  height: 14px;
  width: 14px;
}

.Checkbox__nativeInput:disabled {
  cursor: initial;
}

.Checkbox__nativeInput[readonly],
.Checkbox__nativeInput:read-only {
  cursor: inherit;
}
