@use "/src/sass/theme.scss";

.FormSection {
  display: flex;
  flex-direction: column;
  background-color: theme.color(white);
}

.FormSection--expanded {
  padding-bottom: 16px;
}

.FormSection__topLine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  border-top: 1px solid theme.color(grey, base);
  cursor: pointer;
}

.FormSection--expanded .FormSection__topLine,
.FormSection:last-of-type .FormSection__topLine {
  border-bottom: 1px solid theme.color(grey, base);
}

.FormSection__topLine h2 {
  @include theme.typography("h4");
}

.FormSection__fields {
  padding: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
