@use "/src/sass/theme.scss";

.DocumentHistoryTable__Cell__Client {
  @include theme.typography("body-small-emphasis");
}

.DocumentHistoryTable__Cell__Actions {
  display: flex;
  justify-content: flex-end;
}
