@use "/src/sass/theme.scss";

// Binding local var to SASS colors fn since direct use is impossible in CSS vars
$toastify-color-light: theme.color(white);
$toastify-color-dark: theme.color(black);
$toastify-color-info: theme.color(blue);
$toastify-color-success: theme.color(green);
$toastify-color-error: theme.color(red);

.Toastify {
  --toastify-color-light: #{$toastify-color-light};
  --toastify-color-dark: #{$toastify-color-dark};
  --toastify-color-info: #{$toastify-color-info};
  --toastify-color-success: #{$toastify-color-success};
  --toastify-color-warning: #{$toastify-color-error};
  --toastify-color-error: #{$toastify-color-error};
}

.Toastify__toast-body {
  @include theme.typography(body-small-emphasis);
  color: theme.color(white);
}
