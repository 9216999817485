@use "/src/sass/theme.scss";

.ProfilePage {
  display: flex;
  gap: 32px;
}

.ProfilePage__Form {
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.ProfilePage__Form__InformationsForm {
  padding: 32px 24px;
  border: 1px solid theme.color(grey, base);
  border-radius: 12px;
}

.ProfilePage__Plan {
  flex: 1;
}

.ProfilePage__Plan .PlanItem {
  outline: none;
  min-height: 635px;
}
