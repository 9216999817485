@use "/src/sass/theme.scss";

.Input__container {
  display: inline-flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 44px;
}

.Input__container input {
  @include theme.typography(body-small-emphasis);
  width: 100%;
  height: 100%;
  outline: none;
  outline-width: 0;
  padding-top: 14px;
  padding-left: 12px;
  padding-right: 36px;
  border: none;
  border-radius: 4px;
  color: theme.color(black);
  transition: box-shadow theme.get-animation(speed, controllers)
    theme.get-animation(ease, default);
}

.Input__container .Input__label--default,
.Input__container .Input__label--focused {
  font-size: 11px;
  text-overflow: ellipsis;
  user-select: none;
  pointer-events: none;
  left: 8px;
  line-height: 2px;
  height: 6px;
  padding: 0 4px;
  position: absolute;
  transition:
    top theme.get-animation(speed, controllers)
      theme.get-animation(ease, default),
    color theme.get-animation(speed, controllers)
      theme.get-animation(ease, default),
    font-size theme.get-animation(speed, controllers)
      theme.get-animation(ease, default);
}

.Input__container .Input__label--default {
  font-size: 14px;
  line-height: 6px;
  font-weight: 400;
  color: theme.color(grey, mid);
  top: 50%;
  transform: translateY(-50%);
}

.Input__container .Input__label--focused {
  top: 12px;
}

.Input__container .Input__label--disabled {
  font-weight: 400;
  color: theme.color(grey, mid);
}

.Input__container .Input__icon {
  line-height: 40px;
  user-select: none;
  pointer-events: none;
  position: absolute;
  right: 12px;
}

// default

.Input__container--default input {
  box-shadow: inset 0 0 0 1px theme.color(grey);
  background-color: theme.color(white);
}

.Input__container--default input:focus {
  outline: none;
  outline-width: 0;
  box-shadow: inset 0 0 0 2px theme.color(primary);
}

.Input__container--default .Input__label--focused {
  color: theme.color(primary);
}

.Input__container--default-filled input {
  box-shadow: inset 0 0 0 1px theme.color(grey);
}

// error

.Input__container--error input {
  box-shadow: inset 0 0 0 2px theme.color(red);
  background-color: theme.color(white);
}

.Input__container--error .Input__label--focused {
  color: theme.color(red);
}

// disabled

.Input__container--disabled input {
  background-color: theme.color(background);
  cursor: not-allowed;
  user-select: none;
  box-shadow: inset 0 0 0 1px theme.color(grey, mid);
}

.Input__container--disabled label,
.Input__container--disabled input {
  color: theme.color(grey, mid);
}

// Type date
.Input__container input[type="date"] {
  padding-right: 11px;
  cursor: text;
}

.Input__container input[type="date"]::-webkit-calendar-picker-indicator {
  margin-top: -12px;
  color: theme.color(grey, mid);
  cursor: pointer;
}
