@use "/src/sass/theme.scss";

.Overlay {
  position: fixed;
  background-color: theme.color(background, translucid);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Modal {
  max-width: 640px;
  padding: 32px 24px;
  margin: 0 32px 10vh 32px;
  border-radius: 12px;
  box-shadow: 8px 8px 12px rgba(0, 0, 0, 0.02);
  background-color: theme.color(white);
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.Modal__Content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  @include theme.typography("body");
  line-height: 24px;
  text-wrap: pretty;
}

.Modal__Content a {
  color: theme.color(primary, base);
  @include theme.typography("body-emphasis");
}

.Modal__Buttons {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
  gap: 16px;
}
